<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          <v-text-field v-model="filterText" style="max-width: 280px" append-icon="mdi-magnify" label="Search"
            placeholder="Search" color="tertiary" light clearable />
        </v-card-title>
        <v-data-table :headers="table.headers" :items="filteredItems" class="elevation-1" item-key="name"
          :options.sync="table.options" hide-default-footer>
          <template slot="item" slot-scope="row">
            <tr>
              <td>
                {{ row.item.name }}
              </td>
              <td>
                {{ row.item.engName }}
              </td>
              <td>
                {{ row.item.type }}
              </td>
              <td>
                {{ row.item.duration.join(", ") }}
              </td>
              <td>
                {{ row.item.requirements }}
              </td>
              <td class="text-xs-right">
                <v-btn v-if="row.item.description" icon @click.stop.prevent="row.expand(!row.isExpanded)">
                  <v-icon v-if="row.isExpanded">mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <template #expanded-item="{ headers, item }">
            <tr>
              <td class="pa-0" :colspan="headers.length">
                <v-card flat>
                  <v-card-text>
                    <p class="primary--text caption font-weight-bold">Description</p>
                    <p v-html="item.description" />
                  </v-card-text>
                </v-card>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
/**
 * @project personal-idenfit
 * @developer Halil Kılıçarslan
 * @description shifts Component Logic
 * @date 25.07.2020
 */

const PAID_TYPE = {
  PAID: "PAID",
  UNPAID: "UNPAID",
  PAID_UNPAID: "PAID_UNPAID",
  ON_HOLD: "ON_HOLD",
  MUST_BE_REQUESTED: "MUST_BE_REQUESTED"
};
const DURATION_TYPE = {
  FULL_DAY: "FULL_DAY",
  HALF_DAY: "HALF_DAY",
  QUARTER_DAY: "QUARTER_DAY",
  HOURLY: "HOURLY"
};

export default {
  name: "LeaveTypesOverview",
  data: (vm) => ({
    filterText: null,
    filterableCols: ["name", "engName", "requirements", "description"],
    table: {
      options: {
        sortBy: ["name"],
        sortDesc: [false],
        itemsPerPage: 100
      },
      items: [
        {
          name: "Yıllık İzin",
          engName: "Annual Leave",
          type: PAID_TYPE.PAID,
          duration: [DURATION_TYPE.FULL_DAY, DURATION_TYPE.HALF_DAY],
          requirements: "Only Blue Badge employee with sufficient accruals balance.",
          description: `Employees are allocated with the annual leave at the time of joining. Please note that according to Turkish Labour Law, actual vacation days accrue on a yearly basis and employees who have completed at least one year of service are entitled to annual leave. Exceptionally, in Amazon Turkey employees are entitled to a vacation period of 14 workdays during their first year of employment on a pro-rated basis, i.e., in proportion to the worked period. In case employee terminates their employment before one-year completion, employer will deduct the spent and already paid annual leave from their bank accounts.
    The length of annual leave varies according to the length of employment. Following is the current vacation day's entitlement: 
<ul>
  <li>0-5 years of service (including 5th year): 14 workdays</li>
  <li>6-15 years of service: 20 workdays</li>
  <li>15 or more years of service: 26 workdays </li>
</ul>
    For employees who are at the age of eighteen and below, and those who are at the age of fifty and above, the length of annual leave with pay must not be less than 20 days.`
        },
        {
          name: "Evlilik İzni",
          engName: "Marriage Leave",
          type: PAID_TYPE.PAID,
          duration: [DURATION_TYPE.FULL_DAY],
          requirements: "Copy of marriage certificate .",
          description: `Employees are legally entitled to 3 days of paid leave following marriage. Marriage leave should be taken immediately following the marriage of the employee and cannot be deferred to following months. `
        },
        {
          name: "İlave Ebeveyn İzni",
          engName: "Additional Parental Leave",
          type: PAID_TYPE.PAID,
          duration: [DURATION_TYPE.FULL_DAY],
          requirements: "Only Blue Badge employee. Copy of the child birth certificate.",
          description: `The Parental Leave entitlement is determined by the employee’s completed years of service at the time that the child is born:
For employees with >=1 years of being directly employed by Amazon: 6 weeks of paid leave including the 5 days of statutory Paternity Leave. 
Additional Parental Leave requires HR approval. HR will review attached documents and check if EE is eligible for leave.`
        },
        {
          name: "Yasal Babalık İzni",
          engName: "Statutory Paternity Leave",
          type: PAID_TYPE.PAID,
          duration: [DURATION_TYPE.FULL_DAY],
          requirements: "Copy of the child birth certificate.",
          description: `New fathers are legally entitled to 5 paid leave days following the birth of their child if the child is born in a marriage union.`
        },
        {
          name: "Evlat Edinme Halinde İzin",
          engName: "Adoption Leave",
          type: PAID_TYPE.PAID,
          duration: [DURATION_TYPE.FULL_DAY],
          requirements: "Copy of child adoption documents.",
          description: `Employees who adopt a child are entitled to 5 days of paid leave, regardless of the age of the child. 
In case of the adoption of a child below the age of 3, the adopting employee or one of the adopting spouses can benefit from:
<ul>
<li>Post-partum mandatory maternity leave of 8 weeks starting from the actual delivery date of the adopted child and
<li>Unpaid leave of up to 6 months or unpaid leave for a period equal to the half of their weekly working time, which are mentioned above.
</ul>`
        },
        {
          name: "Süt İzni",
          engName: "Nursing Leave",
          type: PAID_TYPE.PAID,
          duration: [DURATION_TYPE.FULL_DAY, DURATION_TYPE.HALF_DAY, DURATION_TYPE.HOURLY],
          requirements: "",
          description: `Female employees are eligible for nursing leave after the birth and return to job. The eligibility is 1,5 hours per working day required by law. This eligibility starts after returning from the respective maternity leave and ends when the baby is one year old. Bulk usage of this leave type is possible. `
        },
        {
          name: "Doğum Sonrası Yarım Çalışma",
          engName: "Equal Period Leave",
          type: PAID_TYPE.UNPAID,
          duration: [DURATION_TYPE.FULL_DAY],
          requirements: "The statutory maternity leave period must be completed",
          description: ` New mothers can request to work for the half of their weekly working time for the below periods: 
<ul>
<li>first birth: 60 days 
<li>second birth: 120 days 
<li>following births: 180 days 
<li>Multiple babies: +30 days
<li>In case of disabled child: 360 days
</ul>
During this period, nursing leave cannot be requested.`
        },
        {
          name: "Hastalık İzni İki Gün Altı",
          engName: "Sick Leave (Under 2 days)",
          type: PAID_TYPE.PAID,
          duration: [DURATION_TYPE.FULL_DAY, DURATION_TYPE.HALF_DAY],
          requirements: "Medical report .",
          description: `1) first 2 days paid by Amazon 
2) from 3rd day paid by Social security.
Employees who take sick leave should obtain an "incapacity to work report" from a medical provider. The report will state the number of days of leave and reason. Employers are required to adhere to the report issued by the medical provider and must not allow the employee to work during the incapacity period. `
        },
        {
          name: "Hastalık İzni İki Gün Üzeri",
          engName: "Sick Leave (More than 2 days)",
          type: PAID_TYPE.PAID_UNPAID,
          duration: [DURATION_TYPE.FULL_DAY],
          requirements: "Medical report.",
          description: `1) first 2 days paid by Amazon 
2) from 3rd day paid by Social security.
Employees who take sick leave should obtain an "incapacity to work report" from a medical provider. The report will state the number of days of leave and reason. Employers are required to adhere to the report issued by the medical provider and must not allow the employee to work during the incapacity period. `
        },
        {
          name: "Acil İzin (Ücretli)",
          engName: "Emergency Leave (Paid)",
          type: PAID_TYPE.PAID,
          duration: [DURATION_TYPE.FULL_DAY, DURATION_TYPE.HALF_DAY, DURATION_TYPE.HOURLY],
          requirements: "None",
          description: `As per the provision of the Turkish Labor Law, employees are entitled to 3 days of paid leave in the event of marriage, child adoption, and death of the employee’s mother, father, spouse, brother or sister, and child`
        },
        {
          name: "Aile İzni",
          engName: "Family Leave",
          type: PAID_TYPE.PAID,
          duration: [DURATION_TYPE.FULL_DAY],
          requirements: "Medical report confirming the child’s disability or medical status",
          description: `An employee who is the parent of a child with a disability rating of at least 70% or a child, who receives treatment for a chronic disease, is entitled to 10 days’ paid leave per year, which can be taken in installments.`
        },
        {
          name: "Ölüm İzni ( 1. Derece )",
          engName: "Bereavement Leave (1st Degree)",
          type: PAID_TYPE.PAID,
          duration: [DURATION_TYPE.FULL_DAY],
          requirements: "Copy of the death certificate.",
          description: `3 paid days required by law in the event of the death of a 1st degree relative.`
        },
        {
          name: "Ölüm İzni ( 2. Derece )",
          engName: "Bereavement Leave (2nd Degree)",
          type: PAID_TYPE.PAID,
          duration: [DURATION_TYPE.FULL_DAY],
          requirements: "Only Blue Badge employee, Copy of the death certificate",
          description: `Amazon Employees are entitled to 5 paid leave days in the event of a death in the immediate family. This entitlement is inclusive of the 3 paid days required by law in the event of the death of a 1st degree relative.`
        },
        {
          name: "İş Arama İzni",
          engName: "Job Search Leave",
          type: PAID_TYPE.PAID,
          duration: [DURATION_TYPE.FULL_DAY, DURATION_TYPE.HOURLY],
          requirements: "Only Blue Badge employee, Only permanent employees",
          description: `This applies if (I) we dismiss; (ii) the employee is working notice; and (iii) if the employee requests it.
The right is to two hours a day to be allowed for job search. This is paid working hours and should be agreed with manager / HR.
 Leave should not be less than 2 hours daily with the option to be combined in days. `
        },
        {
          name: "Covid İzni",
          engName: "Covid Leave",
          type: PAID_TYPE.PAID,
          duration: [DURATION_TYPE.FULL_DAY],
          requirements: "",
          description: ``
        },
        {
          name: "Elektrik / İnternet Kesintisi İzni - Ücretli",
          engName: "Power/Internet Outage - Paid",
          type: PAID_TYPE.PAID,
          duration: [DURATION_TYPE.FULL_DAY, DURATION_TYPE.HALF_DAY, DURATION_TYPE.HOURLY],
          requirements: `Only eligible for Virtual working employees; 
Notify their direct manager or another member of leadership',
    description: An Internal Amazon Outage is defined as, “Failure of, or missing systems, equipment and/or software owned by Amazon”.`
        },
        {
          name: "Elektrik / İnternet Kesintisi İzni - Ücretsiz",
          engName: "Power/Internet Outage – Unpaid",
          type: PAID_TYPE.UNPAID,
          duration: [DURATION_TYPE.FULL_DAY, DURATION_TYPE.HALF_DAY, DURATION_TYPE.HOURLY],
          requirements:
            "Only eligible for Virtual working employees; Notify their direct manager or another member of leadership",
          description: `An Employee System Outage is defined as “Failure of non-Amazon owned equipment and small service provider infrastructure, as well as password issues”.`
        },
        {
          name: "İş Seyahati",
          engName: "Business Travel",
          type: PAID_TYPE.PAID,
          duration: [DURATION_TYPE.FULL_DAY],
          requirements: "",
          description: ``
        },
        {
          name: "Özel İzin (Covid Aşısı)",
          engName: "Special Time Off (Covid Vaccination)",
          type: PAID_TYPE.ON_HOLD,
          duration: [DURATION_TYPE.FULL_DAY],
          requirements: "Only Blue Badge employee",
          description: `On hold`
        },
        {
          name: "Askerlik İzni",
          engName: "Military Leave",
          type: PAID_TYPE.UNPAID,
          duration: [DURATION_TYPE.FULL_DAY],
          requirements: "Employee must have been employed for a minimum of one year.",
          description: `Unpaid time off - 2 types based on length:
1)  3 weeks military service, there is no termination procedure, just unpaid leave and the employee returns to the job after the 3 weeks
2)  6 months military service, there is a termination but if after the 6 months the employee request to return to amazon against, we should rehire them again.`
        },
        {
          name: "Bahçe İzni",
          engName: "Garden Leave",
          type: PAID_TYPE.PAID,
          duration: [DURATION_TYPE.FULL_DAY],
          requirements: "Employer justification",
          description: `The Company has the right to require you not to come to the Company's offices on full pay and benefits and on such conditions as the Company may specify from time to time ("Garden Leave"), if deemed necessary (e.g. during all or any part of any notice period whether given by you or the Company). 
Please refer to your contract.`
        },
        {
          name: "Ücretsiz İzin",
          engName: "Unpaid Leave",
          type: PAID_TYPE.UNPAID,
          duration: [DURATION_TYPE.FULL_DAY, DURATION_TYPE.HALF_DAY, DURATION_TYPE.HOURLY],
          requirements: "None",
          description: `This type of leave may be arranged between the employee and employer.`
        },
        {
          name: "İşverenden Kaynaklı İzin",
          engName: "Obstacle on Employer",
          type: PAID_TYPE.PAID,
          duration: [DURATION_TYPE.FULL_DAY, DURATION_TYPE.HALF_DAY],
          requirements: "Employer justification",
          description: `This leave is granted by employer in case there was an obstacle on the employer side working conditions.`
        },
        {
          name: "Doğum İzni",
          engName: "Maternity",
          type: PAID_TYPE.PAID,
          duration: [DURATION_TYPE.FULL_DAY],
          requirements: "Must be requested at your HR",
          description: `For employees with one or more years of employment in Amazon by birth date
= 16 weeks at 100% of pay, including the minimum 3 weeks of pre-partum leave
For employees with up to a year of employment in Amazon by birth date =14 weeks at 100% of pay, including the minimum 3 weeks of pre-partum leave
An additional 2 weeks of leave paid at statutory allowance.
An additional 2 weeks of leave paid at statutory allowance in case of multiple pregnancies.
If the employee is eligible for maternity benefits under the Turkish social insurance program, where the social insurance pay benefit is lower than 100% of the base rate as defined above, the Maternity Leave benefit will top up those payments to 100% for the duration of the leave as described above.`
        },
        {
          name: "Mazeret İzni",
          engName: "Excuse Leave",
          type: PAID_TYPE.PAID,
          duration: [DURATION_TYPE.FULL_DAY, DURATION_TYPE.HALF_DAY],
          requirements: "Eligible for all FC branch",
          description: `As per the provision of the Turkish Labor Law, employees are entitled to 3 days of paid leave in the event of marriage, child adoption, and death of the employee’s mother, father, spouse, brother or sister, and child.`
        }
      ],
      headers: [
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "name",
          class: ["primary--text"]
        },
        {
          text: "English Name",
          align: "left",
          sortable: true,
          value: "engName",
          class: ["primary--text"]
        },
        {
          text: "Type",
          align: "left",
          sortable: true,
          value: "type",
          class: ["primary--text"]
        },
        {
          text: "Duration",
          align: "left",
          sortable: false,
          class: ["primary--text"]
        },
        {
          text: "Requirements",
          align: "left",
          sortable: false,
          class: ["primary--text"]
        },
        {
          text: "",
          align: "center",
          sortable: false,
          width: "1%"
        }
      ]
    }
  }),
  computed: {
    filteredItems() {
      return this.filterText
        ? this.table.items.filter((item) =>
          this.filterableCols.some(
            (col) => item[col] && item[col].toLocaleLowerCase().indexOf(this.filterText.toLocaleLowerCase()) > -1
          )
        )
        : this.table.items;
    }
  }
};
</script>
